$(document).ready(() => {
	
	$('.close-btn-special').click(function () {
		$('.specialAction').remove();
    });	

	$('.close-btn-special-mobile').click(function () {
		$('.specialActionMobile').remove();
    });	
});


(function () {
	"use strict";
	let target = document.querySelectorAll('a[href^="#"]');
	let i = 0;
	for (i=0; i<target.length; i++) {
		 target[i].onclick = function (e) {
				let hash = this.getAttribute("href");
				let elem = document.getElementById(hash.replace("#",""));
				
				var y = elem.getBoundingClientRect().top + window.pageYOffset - 100;
				//history.pushState (null, null, hash);
				window.scrollTo({top: y, behavior: 'smooth'});
				e.preventDefault();
		}
	}
	})();